import { RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CallToActionLink from '../../common/components/callToAction/CallToActionLink';
import { Container } from '../../common/components/grid/Grid';
import Layout from '../../common/components/layout/Layout';
import Link from '../../common/components/link/Link';
import { H2 } from '../../common/components/texts';
import Routes from '../app/Routes';
import TabMenu from './components/TabMenu';
import { fetchCurrentUser } from './forces/actions';
import { getCurrentUser } from './forces/selectors';
import styles from './UserPage.module.css';

type Props = {} & React.HTMLAttributes<HTMLElement> & RouteComponentProps;

export enum TabMenus {
  Overview = 'oversikt',
  Bookings = 'bookinger',
  OlioPoints = 'olio-poeng',
}

export enum TabMenuContent {
  Overview = 'overview',
  UserBookings = 'userBookings',
  MyOlioPoints = 'myOlioPoints',
  Referfriend = 'referFriend',
  UserInfo = 'userInfo',
  ChangePassword = 'changePassword',
  BookingInfo = 'bookingInfo',
}

const UserPage = () => {
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const greetingText = `Hei, ${user.name} 💅 `;

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  return (
    <Layout>
      <Container className={styles.container}>
        <H2 className={styles.sectionTitle}>{greetingText}</H2>
        <TabMenu />
        <div className={styles.footerMenuContainer}>
          <CallToActionLink to={Routes.Main()} styleType="tertiary">
            Book time
          </CallToActionLink>
          <Link underlined to={Routes.Logout()} className={styles.logoutButton}>
            Logg ut
          </Link>
        </div>
      </Container>
    </Layout>
  );
};

export default UserPage;

import { useSelector } from 'react-redux';
import { H2 } from '../../../../../common/components/texts';
import { Service } from '../../../../../root.types';
import { getBookingContext } from '../../../forces/selectors';
import { BookingContext } from '../../../forces/types';
import { Clinic as ClinicType } from '../../clinicSection/forces/types';
import { Resource as ResourceType } from '../../resourceSection/types';
import styles from '../SummarySection.module.css';
import Clinic from './Clinic';
import DateAndTime from './DateAndTime';
import OlioPoints from './OlioPoints';
import Promocode from './Promocode';
import Resource from './Resource';
import Services from './Services';
import TotalPrice from './TotalPrice';
import { getFilteredServices } from '../../../forces/booking.utils';

interface Props {
  services: Service[];
  clinic: ClinicType;
  resource: ResourceType;
  time: string;
}

const BookingOverview = ({ services, clinic, resource, time }: Props) => {
  const bookingContext = useSelector(getBookingContext);
  const isOnUserPage = bookingContext === BookingContext.UserPage;
  const { filteredServices } = getFilteredServices(services);

  return (
    <div className={styles.bookingOverviewContainer}>
      <H2 className={styles.header}>Oversikt</H2>
      <Services services={services} />
      <Clinic clinic={clinic} />
      <Resource resource={resource} />
      <DateAndTime time={time} services={filteredServices} />
      {!isOnUserPage && (
        <>
          <OlioPoints services={filteredServices} />
          <Promocode />
        </>
      )}
      <TotalPrice services={filteredServices} />
    </div>
  );
};

export default BookingOverview;

import { TimeSlot as TimeSlotType, TimeSlotRenderProps } from '@askeladden/availability-ui';
import classNames from 'classnames';
import * as dateUtils from '../../../../../common/utils/date.utils';
import { Slot } from '../forces/types';
import styles from './TimePicker.module.css';

const TimePicker = (
  timeSlot: TimeSlotType & TimeSlotRenderProps & Slot,
  showNumAvailableSlots?: boolean
) => {
  const isAvailable = timeSlot.isAvailable;
  const isSelected = timeSlot.isSelected;
  const numberOfAvailableSlots = timeSlot.numberOfAvailableSlots;

  const determineStyling = () => {
    if (isSelected) {
      return styles.selected;
    }
    if (!isAvailable) {
      return styles.unavailable;
    }
    if (!isSelected) {
      return styles.unselected;
    }
  };
  let buttonStyle = determineStyling();
  const cssClassName = classNames(styles.button, buttonStyle);

  return (
    <button className={cssClassName} disabled={!isAvailable}>
      {dateUtils.getHourMinFormatFromIso(timeSlot.time)}
      <br />
      {`${showNumAvailableSlots ? numberOfAvailableSlots : ''} ledig`}
    </button>
  );
};

export default TimePicker;

import { useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import {
  getSelectedClinic,
  getSelectedResource,
  getSelectedServices,
  getSelectedTime,
} from '../../forces/selectors';
import { BookingSectionIDs } from '../../forces/types';
import BookingOverview from './components/BookingOverview';
import styles from './SummarySection.module.css';

const SummarySection = () => {
  const selectedServices = useSelector(getSelectedServices);
  const selectedClinic = useSelector(getSelectedClinic);
  const selectedResource = useSelector(getSelectedResource);
  const selectedTime = useSelector(getSelectedTime);

  return (
    <Scroll.Element name={BookingSectionIDs.Summary}>
      <section className={styles.container}>
        <BookingOverview
          services={selectedServices}
          clinic={selectedClinic}
          resource={selectedResource}
          time={selectedTime}
        />
      </section>
    </Scroll.Element>
  );
};

export default SummarySection;

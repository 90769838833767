import { RouteComponentProps } from '@reach/router';
import { Field, FieldProps, Form, FormikBag, FormikProps, withFormik } from 'formik';
import { posthog } from 'posthog-js';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import CallToActionButton from '../../../common/components/callToAction/CallToActionButton';
import FormInput from '../../../common/components/form/FormInput';
import FormInputError from '../../../common/components/form/FormInputError';
import { BodyBolderText, H2 } from '../../../common/components/texts';
import { parseQueryString } from '../../../common/utils/url.utils';
import { appConfig } from '../../../config';
import { RootState } from '../../../root.reducer';
import { registerUser } from '../forces/actions';
import { getRegisterError } from '../forces/selectors';
import { formFields, FormValues, validation } from './fields/generalFormFields';
import styles from './RegistrationGeneralForm.module.css';

interface OwnProps {}
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & StateProps & OwnProps & RouteComponentProps;
type PropsWithFormik = Props & React.HTMLAttributes<HTMLElement> & FormikProps<FormValues>;

class RegistrationGeneralForm extends React.Component<PropsWithFormik> {
  static getParsedQueryString(props: Props) {
    return parseQueryString(props.location ? props.location.search : '');
  }

  render() {
    const parsedQueryString = RegistrationGeneralForm.getParsedQueryString(this.props);
    const hasReferralCode = parsedQueryString.referralCode;
    const { isSubmitting } = this.props;
    return (
      <>
        <H2 className={styles.title}>Bli medlem</H2>
        <Form>
          <BodyBolderText className={styles.explanationText}>
            Få hver 10 behandling gratis, verv venner og få eksklusive medlemstilbud. Medlemskapet
            er gratis. Som medlem tjener du {appConfig.bookingPointsEarningsPercentage}% olio-poeng
            på alle behandlinger. 1 olio-poeng = 1 kr.{' '}
            {hasReferralCode &&
              'Du får i tillegg 200 olio-poeng på din konto etter første behandling som følge av verving'}
          </BodyBolderText>
          {formFields.map((formField) => (
            <React.Fragment key={formField.name}>
              <Field
                name={formField.name}
                render={({ field }: FieldProps<FormValues>) => {
                  const shouldDisableField = parsedQueryString[field.name] != null;
                  return (
                    <FormInput
                      {...field}
                      styleType="secondary"
                      type={formField.type}
                      required={formField.required}
                      placeholder={formField.placeholder}
                      disabled={shouldDisableField}
                    />
                  );
                }}
              />
              <FormInputError name={formField.name} component="div" />
            </React.Fragment>
          ))}
          <CallToActionButton
            styleType="tertiary"
            size="large"
            type="submit"
            disabled={isSubmitting}
          >
            Registrer
          </CallToActionButton>
        </Form>
      </>
    );
  }
}

const formOptions = {
  mapPropsToValues: (props: Props) => {
    const queryString = parseQueryString(window.location.search);
    const defaultValues = {
      name: '',
      email: '',
      phoneNumber: '',
      password: '',
      referralCode: (queryString.referralCode as string) || '',
    };
    return { ...defaultValues };
  },
  handleSubmit: async (values: FormValues, formikBag: FormikBag<Props, FormValues>) => {
    try {
      formikBag.props.registerUser(values);
      formikBag.setSubmitting(false);
      posthog.capture('Member registered', { email: values.email });
    } catch (err) {
      formikBag.setSubmitting(false);
      formikBag.setStatus(err.message || 'Noe gikk galt!');
    }
  },
  validationSchema: validation,
};
const FormikRegisterPage = withFormik<Props, FormValues>(formOptions)(RegistrationGeneralForm);

const mapStateToProps = (state: RootState) => ({
  error: getRegisterError(state),
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      registerUser,
    },
    dispatch
  );

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(FormikRegisterPage);

import { getCookies, identifyUser } from '../../../common/analytics/segment';
import { APIRequest, Service } from '../../../root.types';
import { GuestInfo, User, UserResponse } from '../../user/forces/types';
import { Clinic } from '../sections/clinicSection/forces/types';
import { Resource } from '../sections/resourceSection/types';
import { mapResponseToBooking } from './mappers';

const isUserWithMemberRoleExists =
  (request: APIRequest) => async (token: string, phoneNumber: string) => {
    return await request<{ isUserWithMemberRoleExists: boolean }>({
      method: 'get',
      path: `/users/current/${phoneNumber}`,
      headers: {
        Authorization: token,
      },
    });
  };

const fetchBooking =
  (request: APIRequest) =>
  async ({
    token,
    clinicId,
    bookingId,
  }: {
    token: string;
    clinicId: string;
    bookingId: string;
  }) => {
    const response = await request<Array<{ [key: string]: any }>>({
      method: 'get',
      path: `/users/current/bookings/${clinicId}/${bookingId}`,
      headers: {
        Authorization: token,
      },
    });

    return mapResponseToBooking(response);
  };

const createBooking =
  (request: APIRequest) =>
  async (parameters: {
    token: string;
    services: Service[];
    clinic: Clinic;
    resourceIds: string[];
    date: string;
    time: string;
    useOlioPoints: boolean;
    promocode?: string;
    userData?: GuestInfo;
    isMemberBookedAsGuest?: boolean;
  }) => {
    const { token, services, clinic, resourceIds, time, useOlioPoints, promocode, userData } =
      parameters;
    const clinicId = clinic.id;
    if (userData) {
      await identifyUser(userData);
    }
    const cookies = getCookies();
    return request({
      method: 'post',
      path: `/clinics/${clinicId}/bookings`,
      headers: {
        Authorization: token,
      },
      body: JSON.stringify({
        date: time,
        serviceList: services,
        resourceIds,
        useOlioPoints,
        promocode,
        clinicName: clinic.name,
        cookies,
        isMemberBookedAsGuest: parameters.isMemberBookedAsGuest,
      }),
    });
  };

const createRebooking =
  (request: APIRequest) =>
  async (parameters: {
    userId: string;
    services: Service[];
    clinic: Clinic;
    resource?: Resource;
    date: string;
    time: string;
    useOlioPoints: boolean;
    promocode?: string;
    isMemberBookedAsGuest?: boolean;
    rebookingPrevBookingId: string;
  }) => {
    const {
      userId,
      services,
      clinic,
      resource,
      time,
      useOlioPoints,
      promocode,
      rebookingPrevBookingId,
    } = parameters;
    const clinicId = clinic.id;
    const resourceIds = resource ? [resource.id] : [];
    const cookies = getCookies();
    return request({
      method: 'post',
      path: `/clinics/${clinicId}/rebookings`,
      body: JSON.stringify({
        userId,
        date: time,
        serviceList: services,
        resourceIds,
        useOlioPoints,
        promocode,
        clinicName: clinic.name,
        cookies,
        isMemberBookedAsGuest: parameters.isMemberBookedAsGuest,
        isRebooking: true,
        rebookingPrevBookingId,
      }),
    });
  };

const updateBooking =
  (request: APIRequest) =>
  async (parameters: {
    token: string;
    bookingId: string;
    services: Service[];
    clinic: Clinic;
    resource: Resource | null;
    date: string;
    time: string;
    useOlioPoints: boolean;
    user: User;
  }) => {
    const { token, bookingId, services, clinic, time, useOlioPoints, resource, user } = parameters;
    const clinicId = clinic.id;
    const resourceIds = resource ? [resource.id] : [];
    await identifyUser(user);
    const cookies = getCookies();
    const body = JSON.stringify({
      date: time,
      serviceList: services,
      resourceIds,
      useOlioPoints,
      cookies,
    });
    const path = `/users/current/bookings/${clinicId}/${bookingId}`;
    return request({
      method: 'put',
      path,
      headers: {
        Authorization: token,
      },
      body,
    });
  };

const deleteBooking =
  (request: APIRequest) =>
  async (token: string, bookingId: string, clinicId: string, user: User, bookingInfo: any) => {
    await identifyUser(user);
    const cookies = getCookies();
    const response = await request<UserResponse>({
      method: 'delete',
      path: `/users/current/bookings/${clinicId}/${bookingId}`,
      headers: {
        Authorization: token,
      },
      body: JSON.stringify({ cookies, bookingInfo }),
    });
    return response;
  };

export {
  isUserWithMemberRoleExists,
  fetchBooking,
  createBooking,
  createRebooking,
  updateBooking,
  deleteBooking,
};

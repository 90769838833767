import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Service } from '../../../../../root.types';
import { getSelectedServices } from '../../../forces/selectors';
import CheckboxLine from './CheckboxLine';
import styles from './ServiceItem.module.css';
import ServicePrice from './ServicePrice';

interface Props {
  childServices: Service[];
  handleSelection: (service: Service) => void;
  handleDeselection: (service: Service) => void;
  resetSections: () => void;
}
const ChildServiceItems = ({
  childServices,
  handleSelection,
  handleDeselection,
  resetSections,
}: Props) => {
  const selectedServices = useSelector(getSelectedServices);
  const sortedChildServices = useMemo(
    () => childServices.sort((a, b) => (a.sort > b.sort ? 1 : -1)),
    [childServices]
  );

  return (
    <>
      {sortedChildServices.map((childService) => {
        const isSelected =
          selectedServices && selectedServices.find((service) => service.id === childService.id);
        return (
          <div className={styles.childServiceContainer} key={childService.id}>
            <CheckboxLine
              onClick={() => {
                if (isSelected) {
                  handleDeselection(childService);
                } else {
                  handleSelection(childService);
                  resetSections();
                }
              }}
              isSelected={!!isSelected}
              content={childService.displayTitle}
              className={styles.childService}
              tooltip={childService.description}
            />
            <ServicePrice service={childService} />
          </div>
        );
      })}
    </>
  );
};

export default ChildServiceItems;

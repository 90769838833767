import { TimeSlot } from '@askeladden/availability-ui/dist/components/TimeSlotPicker';
import { TimeInfo } from '../../clinicSection/forces/types';

export interface Slot {
  time: string;
  isAvailable: boolean;
  timeInfo: TimeInfo[];
  status: string;
  numberOfAvailableSlots: number;
}

export interface AvailableDate {
  date: string;
  isAvailable: boolean;
  isOpen: boolean;
  slots: Slot[] | [];
}

export interface IGroupedTimeSlots {
  name: string;
  slots: TimeSlot[];
}

export const AcoBookingLocationAvailabilityErrorCode = {
  ResourcesNotFound: 'RESOURCES_NOT_FOUND',
  ResourceTypesNotFound: 'RESOURCE_TYPES_NOT_FOUND',
  ResourcesNotCapable: 'RESOURCES_NOT_CAPABLE',
} as const;

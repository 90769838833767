import classNames from 'classnames';
import posed from 'react-pose';
import { useDispatch, useSelector } from 'react-redux';
import SectionListTitle from '../../../../../common/components/section/SectionListTitle';
import { BodyBolderText } from '../../../../../common/components/texts';
import { Service } from '../../../../../root.types';
import { deselectService, deselectServiceById } from '../../../forces/actions';
import { getBookingContext, getEditMode } from '../../../forces/selectors';
import { BookingContext } from '../../../forces/types';
import styles from '../SummarySection.module.css';
import { getFilteredServices } from '../../../forces/booking.utils';
import Infobox from 'common/components/infobox/Infobox';

interface Props {
  services?: Service[];
}

const Item = posed.li();

const Services = ({ services }: Props) => {
  const bookingContext = useSelector(getBookingContext);
  const isEditMode = useSelector(getEditMode);
  const isOnUserPage = bookingContext === BookingContext.UserPage;
  const dispatch = useDispatch();
  const { filteredServices, excludedServices } = getFilteredServices(services);
  const onlyUniqueServices = filteredServices?.filter(
    (service, index, self) => self.findIndex((s) => s.id === service.id) === index
  );

  const handleDeselectService = (service: Service) => {
    if (service.isRequiredAdditionalService) {
      dispatch(deselectServiceById(service.parentServiceId));
    }
    dispatch(deselectService(service));
  };

  return (
    <ul className={styles.list}>
      <SectionListTitle className={styles.title}>
        <div className={styles.flexTitle}>
          Behandlinger
          {isEditMode && (
            <div className={styles.infoBox}>
              <Infobox
                className={styles.infoSymbol}
                isSelected={false}
                description={
                  'For å fjerne/legge til behandlinger må du kansellere og booke ny time'
                }
                title={'Endring av behandlinger'}
              />
            </div>
          )}
        </div>
      </SectionListTitle>
      {onlyUniqueServices &&
        onlyUniqueServices.map((service: Service) => {
          const isChildAndPackageDeal = service.isChild && !service.isPackageDeal;
          const parentService = excludedServices.find((s) => s.id === service.parentServiceId);
          return (
            <Item key={service.id} className={styles.listItem}>
              <div
                className={classNames(styles.treatmentNameContainer, {
                  [styles.childTreatment]: isChildAndPackageDeal,
                })}
              >
                <BodyBolderText>
                  {parentService && `${parentService.title} - `}
                  {service.title}
                </BodyBolderText>
              </div>
              {!isOnUserPage && !isEditMode && (
                <button
                  className={styles.removeButton}
                  onClick={() => handleDeselectService(service)}
                />
              )}
            </Item>
          );
        })}
    </ul>
  );
};

export default Services;

import classNames from 'classnames';
import * as React from 'react';
import { assertUnreachable } from '../../utils/ts.utils';
import styles from './CallToAction.module.css';

export type CallToActionStyleType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'quinary'
  | 'outlined'
  | 'outlinedSecondary'
  | 'outlinedQuaternary'
  | 'noline';
export type CallToActionSize = 'large' | 'normal';

export interface CallToActionProps {
  styleType?: CallToActionStyleType;
  theme?: 'dark' | 'light' | 'brown';
  size?: CallToActionSize;
}

type Props = {
  element: string | React.FunctionComponent<any>;
  className?: string;
  children?: React.ReactNode;
} & CallToActionProps;

const styleTypeToClassName = (styleType: CallToActionStyleType) => {
  switch (styleType) {
    case 'primary':
      return styles.primary;
    case 'secondary':
      return styles.secondary;
    case 'tertiary':
      return styles.tertiary;
    case 'quaternary':
      return styles.quaternary;
    case 'quinary':
      return styles.quinary;
    case 'outlined':
      return styles.outlined;
    case 'outlinedSecondary':
      return styles.outlinedSecondary;
    case 'outlinedQuaternary':
      return styles.outlinedQuaternary;
    case 'noline':
      return styles.noline;
    default:
      assertUnreachable(styleType);
  }
};

const sizeToClassName = (size: CallToActionSize) => {
  switch (size) {
    case 'large':
      return styles.sizeLarge;
    case 'normal':
      return styles.sizeNormal;
    default:
      assertUnreachable(size);
  }
};

const CallToAction: React.FunctionComponent<Props> = ({
  className,
  styleType = 'primary',
  theme = 'light',
  size = 'normal',
  children,
  element,
  ...props
}) => {
  return React.createElement(element, {
    className: classNames(
      {
        [styles.themeLight]: theme === 'light',
        [styles.themeDark]: theme === 'dark',
        [styles.themeBrown]: theme === 'brown',
      },
      styles.callToAction,
      styleTypeToClassName(styleType),
      sizeToClassName(size),
      className
    ),
    ...props,
    children,
  });
};

export default CallToAction;

import * as React from 'react';
import classNames from 'classnames';
import { BodyBolderText, SmallText } from '../texts';
import styles from './Checkbox.module.css';

type Props = {
  checked?: boolean;
  disabled?: boolean;
  title?: string;
  subtitle?: string;
  onCheckboxChange: (checked: boolean) => void;
} & React.HTMLAttributes<HTMLElement>;

const Checkbox: React.FunctionComponent<Props> = ({
  className,
  title = null,
  checked = false,
  disabled = false,
  subtitle,
  onCheckboxChange,
  ...props
}) => {
  return (
    <label className={classNames(styles.container, className)} {...props}>
      <div>
        <BodyBolderText>{title}</BodyBolderText>
        {subtitle && <SmallText>{subtitle}</SmallText>}
      </div>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(event) => {
          onCheckboxChange(event.target.checked);
        }}
      />
      <span
        className={
          subtitle ? styles.checkmark : classNames(styles.checkmark, styles.withoutSmallText)
        }
      />
    </label>
  );
};

export default Checkbox;

export const removeDuplicatesByProperty = <T>(arr: T[], prop: string): T[] => {
  const seen = new Set();
  const unique: T[] = [];

  arr.forEach((obj) => {
    const objKey = obj[prop];
    if (!seen.has(objKey)) {
      seen.add(objKey);
      unique.push(obj);
    }
  });

  return unique;
};

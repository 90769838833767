import SectionListTitle from '../../../../../common/components/section/SectionListTitle';
import { BodyBolderText } from '../../../../../common/components/texts';
import { Clinic as ClinicType } from '../../clinicSection/forces/types';
import styles from '../SummarySection.module.css';

interface Props {
  clinic: ClinicType;
}

const Clinic = ({ clinic }: Props) => {
  const clinicInfo = clinic ? `${clinic.name} (${clinic.address.streetAddress})` : '';
  return (
    <div>
      <SectionListTitle className={styles.title}>Lokasjon</SectionListTitle>
      <BodyBolderText>{clinicInfo}</BodyBolderText>
    </div>
  );
};

export default Clinic;

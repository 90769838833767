import { RootState } from '../../../root.reducer';

export enum BookingSectionIDs {
  ServiceSelection = 'serviceSelection',
  ClinicSection = 'clinicSection',
  ResourceSection = 'resourceSection',
  GuestSection = 'guestSection',
  CalendarSection = 'calendarSection',
  TimeSection = 'timeSection',
  Summary = 'summary',
  CreateUpdateDeleteBooking = 'createUpdateDeleteBooking',
}

export interface BookingSection {
  id: BookingSectionIDs;
  title?: string;
  component: React.ReactNode;
  appointmentId?: string;
  type: 'primary' | 'secondary' | 'tertiary';
  sectionValidator?: (state: RootState) => boolean;
  className?: string;
}

export interface IButtonState {
  text: string;
  isUpdate: boolean;
  isReady: boolean;
  isEnabled: boolean;
}

export enum BookingContext {
  UserPage = 'UserPage',
  Create = 'Create',
  UPDATE = 'Update',
}

export type Booking = {
  id: string;
  serviceIds: string[];
  clinicId: string;
  time: number;
  resourceId?: string;
  resourceIds?: string[];
  date?: string;
  duration: number;
};

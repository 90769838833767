import { CreateGuestResponse, User, UserResponse } from './types';

export const mapToUser = ({
  _id,
  isActive,
  isFinished,
  phoneNumber,
  referralCode,
  name,
  email,
  role,
  bookings,
}: UserResponse): User => ({
  id: _id,
  isActive,
  isFinished,
  phoneNumber,
  referralCode,
  name,
  email,
  role,
  bookings: bookings.map((booking) => ({
    id: booking.bookingId,
    serviceIds: booking.services,
    clinicId: booking.waitlistId,
    resourceId: booking.resourceIds ? booking.resourceIds[0] : undefined,
    duration: booking.duration,
    time: booking.time,
  })),
});

export const mapToCreateGuestResponse = (response: any): CreateGuestResponse => {
  return response;
};

import { useSelector } from 'react-redux';
import SectionListTitle from '../../../../../common/components/section/SectionListTitle';
import { BodyBolderText, BodyText } from '../../../../../common/components/texts';
import { getSummaryFormatFromIso } from '../../../../../common/utils/date.utils';
import { Service } from '../../../../../root.types';
import { getTotalDuration } from '../../../forces/selectors';
import styles from '../SummarySection.module.css';

interface Props {
  time: string;
  services: Service[];
}

const MESSAGE =
  'Avbestilling/endring må skje senest 24 timer før behandling, og du må møte til oppsatt tid. Timer som blir avbestilt etter fristen vil bli fakturert i sin helhet (gjelder også ved sykdom).';

const DateAndTime = ({ time, services }: Props) => {
  const uniqueServices = services?.filter(
    (service, index, self) => self.findIndex((s) => s.id === service.id) === index
  );
  const totalDuration = useSelector(getTotalDuration)(uniqueServices);
  return (
    <>
      <div className={styles.spaceBetweenContainer}>
        <SectionListTitle className={styles.dateTimeTitle}>Dato & Tid</SectionListTitle>
        <SectionListTitle className={styles.dateTimeTitle}>Varighet</SectionListTitle>
      </div>
      <div className={styles.dateTimeValueContainer}>
        <BodyBolderText>{time && `${getSummaryFormatFromIso(time)}`}</BodyBolderText>
        <BodyBolderText>{totalDuration} min</BodyBolderText>
      </div>
      <BodyText>{MESSAGE}</BodyText>
    </>
  );
};

export default DateAndTime;

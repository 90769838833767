import { RootState } from '../../../root.reducer';
import { Service } from '../../../root.types';
import { getCurrentUser } from '../../user/forces/selectors';
import {
  calculateTotalDuration,
  calculateTotalPrice,
} from '../sections/serviceSection/forces/service.utils';

export const getIsLoadingBooking = (state: RootState) => state.booking.isLoading;
export const getSelectedServices = (state: RootState) => state.booking.selectedServices;
export const getSelectedServiceIds = (state: RootState) =>
  state.booking.selectedServices
    ? state.booking.selectedServices.map((service) => service.waitwhileServiceId)
    : [];
export const getSelectedClinic = (state: RootState) => state.booking.selectedClinic;
export const getSelectedResource = (state: RootState) => state.booking.selectedResource;
export const getSelectedDate = (state: RootState) => state.booking.selectedDate;
export const getSelectedTime = (state: RootState) => state.booking.selectedTime;
export const getSelectedSlot = (state: RootState) => state.booking.selectedSlot;
export const getDateRange = (state: RootState) => state.booking.dateRange;
export const getGuestInfo = (state: RootState) => state.booking.guestInfo;
export const getClinicById = (state: RootState) => (clinicId: string) => {
  if (!clinicId || !state.clinic.clinics) return;
  return state.clinic.clinics.find(
    (clinic) => clinic.id === clinicId || clinic.waitwhileId === clinicId
  );
};
export const getBookingError = (state: RootState) => state.booking.error;
export const getHasSelectedServicesClinicResource = (state: RootState) =>
  state.booking.selectedServices && state.booking.selectedClinic && state.booking.selectedResource;
export const getBookingId = (state: RootState) => state.booking.bookingId;
export const isServiceSelected =
  (state: RootState) =>
  (service: Service): boolean => {
    return getSelectedServiceIds(state).includes(service.waitwhileServiceId);
  };

export const getServiceChildServices =
  (state: RootState) =>
  (parentService: Service): Service[] => {
    if (!isServiceSelected(state)(parentService)) {
      return [];
    }

    const childServices = parentService.additionalServices.filter((service) =>
      isServiceSelected(state)(service)
    );
    return childServices;
  };

export const getIsChildServiceSelected =
  (state: RootState) =>
  (parentService: Service, childService: Service): boolean => {
    const selectedServiceIds = getSelectedServiceIds(state);
    return (
      selectedServiceIds.includes(parentService.waitwhileServiceId) &&
      selectedServiceIds.includes(childService.waitwhileServiceId)
    );
  };

export const numberOfSpecificService = (state: RootState) => (service: Service) => {
  return getSelectedServiceIds(state).filter(
    (serviceId) => serviceId === service.waitwhileServiceId
  ).length;
};

export const hasGuestInfo = (state: RootState) => {
  return Object.values(getGuestInfo(state)).every((value) => value !== '');
};

export const getUseOlioPoints = (state: RootState) => state.booking.useOlioPoints;
export const getEditMode = (state: RootState): boolean => state.booking.bookingId !== null;
export const getTotalPrice = (state: RootState) => (services: Service[]) => {
  if (!services) return 0;
  return calculateTotalPrice(services);
};
export const getTotalDuration = (state: RootState) => (services: Service[]) => {
  if (!services) return 0;
  return calculateTotalDuration(services);
};

export const getPromocode = (state: RootState) => state.booking.promocode;

export const getIsBookingValid = (state: RootState) => {
  const selectedServices = getSelectedServices(state);
  const selectedClinic = getSelectedClinic(state);
  const selectedDate = getSelectedDate(state);
  const selectedTime = getSelectedTime(state);
  const currentUser = getCurrentUser(state);
  const hasFilledOutGuestInfoForm = hasGuestInfo(state);
  if (!currentUser) {
    console.log('!currentUser');
    console.log('selectedServices', selectedServices);
    console.log('selectedClinic', selectedClinic);
    console.log('selectedDate', selectedDate);
    console.log('selectedTime', selectedTime);
    console.log('hasFilledOutGuestInfoForm', hasFilledOutGuestInfoForm);
    return (
      selectedServices &&
      selectedClinic &&
      selectedDate &&
      selectedTime &&
      hasFilledOutGuestInfoForm
    );
  }

  console.log('currentUser');
  console.log('selectedServices', selectedServices);
  console.log('selectedClinic', selectedClinic);
  console.log('selectedDate', selectedDate);
  console.log('selectedTime', selectedTime);
  return selectedServices && selectedClinic && selectedDate && selectedTime;
};

export const getIsRebookingValid = (state: RootState) => {
  const selectedServices = getSelectedServices(state);
  const selectedClinic = getSelectedClinic(state);
  const selectedDate = getSelectedDate(state);
  const selectedTime = getSelectedTime(state);
  return selectedServices && selectedClinic && selectedDate && selectedTime;
};
export const getShowLoginOption = (state: RootState) => state.booking.showLoginOption;
export const getBookingContext = (state: RootState) => state.booking.bookingContext;
export const getInvalidSections =
  (state: RootState) =>
  (
    excludeFields: (
      | 'selectedServices'
      | 'selectedClinic'
      | 'selectedDate'
      | 'selectedTime'
      | 'currentUser'
    )[] = []
  ): string[] => {
    const invalidSections = [];
    if (!state.booking.selectedServices && !excludeFields.includes('selectedServices'))
      invalidSections.push('Mangler valg av behandling');
    if (!state.booking.selectedClinic && !excludeFields.includes('selectedClinic'))
      invalidSections.push('Mangler valg av lokasjon');
    if (!state.booking.selectedDate && !excludeFields.includes('selectedDate'))
      invalidSections.push('Mangler valg av dato');
    if (!state.booking.selectedTime && !excludeFields.includes('selectedTime'))
      invalidSections.push('Mangler valg av klokkeslett');
    const currentUser = getCurrentUser(state);
    if (!excludeFields.includes('currentUser') && !currentUser && !hasGuestInfo(state)) {
      invalidSections.push('Mangler kundeinfo');
    }
    return invalidSections;
  };

export const getInvalidSectionsForRebooking = (state: RootState) => {};

export const getRebookingUserPromptIsSubmitted = (state: RootState) =>
  state.booking.rebookingUserPromptIsSubmitted;

export const getSelectedRebookingUser = (state: RootState) => state.booking.rebookingUser;

import classNames from 'classnames';
import React from 'react';
import { BodyBolderText, H3 } from '../texts';
import styles from './FormAlert.module.css';

type Props = {
  error?: string | null;
  success?: string | null;
} & React.HTMLAttributes<HTMLElement>;

const FormAlert: React.FunctionComponent<Props> = ({ error = null, success = null }) => {
  if (!(error || success)) return null;
  const headerText = error ? 'Oops!' : '';
  const message = error || success;
  return (
    <div
      className={classNames(styles.alert, {
        [styles.alertDanger]: error,
        [styles.alertSuccess]: success,
      })}
    >
      <div className={styles.alertContent}>
        <H3 className={styles.text}>{headerText}</H3>
        <BodyBolderText className={styles.text}>{message}</BodyBolderText>
      </div>
    </div>
  );
};

export default FormAlert;

import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../../../common/components/checkbox/Checkbox';
import SectionListTitle from '../../../../../common/components/section/SectionListTitle';
import { BodyBolderText, BodyText } from '../../../../../common/components/texts';
import { Service } from '../../../../../root.types';
import { getCurrentUser } from '../../../../user/forces/selectors';
import { Role } from '../../../../user/forces/types';
import { setUseOlioPoints } from '../../../forces/actions';
import {
  getEditMode,
  getPromocode,
  getTotalPrice,
  getUseOlioPoints,
} from '../../../forces/selectors';
import styles from '../SummarySection.module.css';
import { useOlioPoints } from '../../../../../common/hooks/useOlioPoints';

interface Props {
  services: Service[];
}

const OlioPoints = ({ services }: Props) => {
  const { data } = useOlioPoints();
  const currentUser = useSelector(getCurrentUser);
  const userIsMember = currentUser && currentUser.role === Role.Member;
  const payWithOlioPoints = useSelector(getUseOlioPoints);
  const totalPrice = useSelector(getTotalPrice)(services);
  const deactivatePointsForServices = services.filter(
    (s) => s.deactivatePayWithOlioPointsForService
  );

  const isEditMode = useSelector(getEditMode);
  const promocode = useSelector(getPromocode);
  const olioPoints = currentUser && data?.usablePoints;
  const dispatch = useDispatch();
  const hasEnoughOlioPoints = olioPoints >= totalPrice;
  const canUseOlioPoint = hasEnoughOlioPoints && !promocode;
  const textWhenCanUseOlioPoint = `Jippi - du har ${olioPoints} Olio-poeng og kan derfor få timen gratis!`;
  const textWhenCannotUseOlioPoint = !promocode
    ? `Du har ${olioPoints}/${totalPrice} Olio-poeng nødvendig for å betale for behandlingen med poeng.`
    : 'Du kan ikke betale med olio-poeng når du har skrevet inn en kampanjekode. Fjern kampanjekoden dersom du ønsker å betale med olio-poeng';

  const showOlioPoint = userIsMember && !isEditMode;
  if (!showOlioPoint) return null;

  const render = () => {
    if (!!deactivatePointsForServices.length) {
      return (
        <BodyText>
          Olio-poeng kan ikke benyttes til å betale for{' '}
          {deactivatePointsForServices.map((s) => s.displayTitle).join(', ')}
        </BodyText>
      );
    }

    if (canUseOlioPoint) {
      return (
        <div className={styles.pointsInfoTextContainer}>
          <BodyBolderText>{textWhenCanUseOlioPoint}</BodyBolderText>
          <Checkbox
            checked={payWithOlioPoints}
            onCheckboxChange={() => dispatch(setUseOlioPoints(!payWithOlioPoints))}
            title={'Betal med olio-poeng'}
            subtitle={'Kan ikke brukes i kombinasjon med kampanjekode'}
            disabled={!canUseOlioPoint}
          />
        </div>
      );
    }

    return (
      <div className={styles.pointsInfoTextContainer}>
        <BodyBolderText>{textWhenCannotUseOlioPoint}</BodyBolderText>
      </div>
    );
  };

  return (
    <>
      <SectionListTitle className={styles.title}>olio-poeng</SectionListTitle>
      {render()}
    </>
  );
};

export default OlioPoints;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CallToActionButton from '../../../common/components/callToAction/CallToActionButton';
import FloatingLabel from '../../../common/components/floatingLabel/FloatingLabel';
import FormAlert from '../../../common/components/form/FormAlert';
import { updateProfileInformation } from '../forces/actions';
import { getCurrentUser } from '../forces/selectors';
import styles from './UserInfo.module.css';

const UserInfo = () => {
  const [message, setMessage] = useState({ success: null, error: null });
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  if (!currentUser) return null;
  const { name, email, phoneNumber } = currentUser;
  const [userData, setUserData] = useState({ name, email, phoneNumber });

  const onError = (errorMessage: string) => {
    setMessage({ success: null, error: errorMessage });
  };

  const onSuccess = () => {
    setMessage({ error: null, success: 'Profilen er oppdatert' });
  };

  const handleUserDataUpdate = () => {
    const isValid = userData.name && userData.email && userData.phoneNumber;
    const isSameInfo =
      userData.name === name && userData.email === email && userData.phoneNumber === phoneNumber;
    if (!isValid)
      return setMessage({ success: null, error: 'Vennligst fyll inn alle påkrevde felter' });
    if (isSameInfo) return setMessage({ success: null, error: null });
    dispatch(updateProfileInformation(userData, onError, onSuccess));
  };

  return (
    <div>
      <FloatingLabel
        defaultValue={name}
        onChange={(evt) => setUserData({ ...userData, name: evt.target.value })}
        label="Navn"
      />
      <FloatingLabel
        defaultValue={email}
        onChange={(evt) => setUserData({ ...userData, email: evt.target.value })}
        label="Epost"
      />
      <FloatingLabel
        defaultValue={phoneNumber}
        onChange={(evt) => setUserData({ ...userData, phoneNumber: evt.target.value })}
        label="Telefonnummer"
      />
      <CallToActionButton
        className={styles.loginButton}
        styleType="tertiary"
        onClick={handleUserDataUpdate}
      >
        Lagre
      </CallToActionButton>
      {message.success ? <FormAlert success={message.success} /> : null}
      {message.error ? <FormAlert error={message.error} /> : null}
    </div>
  );
};

export default UserInfo;

import SectionListTitle from '../../../../../common/components/section/SectionListTitle';
import { BodyBolderText } from '../../../../../common/components/texts';
import { Resource as ResourceType } from '../../resourceSection/types';
import styles from '../SummarySection.module.css';

interface Props {
  resource: ResourceType;
}

const Resource = ({ resource }: Props) => {
  if (!resource) return null;

  return (
    <>
      <SectionListTitle className={styles.title}>Behandler</SectionListTitle>
      <BodyBolderText>{resource.name}</BodyBolderText>
    </>
  );
};

export default Resource;

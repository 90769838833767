import { Booking } from '../../booking/forces/types';

export enum Role {
  Guest = 'guest',
  Member = 'member',
}

export type UserResponse = {
  isActive: boolean;
  isFinished: boolean;
  email: string;
  points: number;
  _id: string;
  name: string;
  role: Role;
  phoneNumber: string;
  bookings: Array<{
    _id: string;
    bookingId: string;
    services: string[];
    waitlistId: string;
    resourceIds: string;
    duration: number;
    time: number;
  }>;
  createdAt: Date;
  updatedAt: Date;
  referralCode: string;
  token: string;
};

export type User = {
  id: string;
  name: string;
  isActive: boolean;
  isFinished: boolean;
  referralCode: string;
  phoneNumber: string;
  email: string;
  role: Role;
  bookings: Booking[];
  cancellationDate?: Date;
  acoBookingId?: string;
};

export type GuestInfo = {
  name: string;
  phoneNumber: string;
  email: string;
};

export enum RegistrationStep {
  GeneralForm,
  Summary,
}

export interface CreateGuestResponse {
  token: string;
}

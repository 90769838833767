import classNames from 'classnames';
import { BodyBolderText, BodyText, SmallText } from '../../../../../common/components/texts';
import { Service } from '../../../../../root.types';
import { getSortedServicesByPrice } from '../forces/service.utils';
import styles from './ServiceItem.module.css';

interface Props {
  service: Service;
}

const FromPrice = ({ price }: { price: number }) => (
  <p className={classNames(styles.servicePrice, styles.bodyText)}>{`fra ${price}`}</p>
);

const ServicePrice = ({ service }: Props) => {
  const hasDiscountPrice =
    typeof service.discountPrice === 'number' && service.discountPrice < service.price;
  const isAdditionalService = service.parentServiceId !== undefined && !service.isPackageDeal;
  const hasRequiredAdditionalServices = service.additionalServices?.some(
    (s) => s.isRequiredAdditionalService
  );

  if (hasDiscountPrice) {
    return (
      <div className={styles.serviceItem}>
        <div className={styles.discountWrapper}>
          <BodyBolderText className={styles.servicePrice}>{service.price}</BodyBolderText>
          <span className={styles.lineThrough} />
        </div>
        <BodyBolderText className={styles.servicePrice}>{service.discountPrice}</BodyBolderText>
      </div>
    );
  }

  const renderPrice = () => {
    // TODO: Special case for Botox-injections. Should be removed/refactored if multiple cases of this happens
    if (service.waitwhileServiceId === 'mB4iFNvdIytmuhH5ICW6') {
      return (
        <BodyBolderText className={styles.servicePrice}>{`fra ${service.price}`}</BodyBolderText>
      );
    }
    if (hasRequiredAdditionalServices) {
      return <FromPrice price={getSortedServicesByPrice(service.additionalServices)[0].price} />;
    }
    if (isAdditionalService) {
      return <BodyBolderText className={styles.servicePrice}>{`+${service.price}`}</BodyBolderText>;
    }
    return <BodyBolderText className={styles.servicePrice}>{service.price}</BodyBolderText>;
  };

  return renderPrice();
};

export default ServicePrice;

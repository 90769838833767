import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Grid } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import Routes from '../../domain/app/Routes';
import { getCurrentUser } from '../../domain/user/forces/selectors';
import { User } from '../../domain/user/forces/types';
import CallToActionLink from '../components/callToAction/CallToActionLink';
import HamburgerMenu from '../components/hamburgerMenu/HamburgerMenu';
import Link from '../components/link/Link';
import Logo from '../components/logo/Logo';
import { H2 } from '../components/texts';
import NavBarBanner from './Banner';
import styles from './Navbar.module.css';

const menuItems = (user: User) => {
  if (user) {
    return [
      {
        title: 'Min olio',
        link: Routes.UserPage(),
        type: 'link',
        external: false,
      },
      {
        title: 'Logg ut',
        link: Routes.Logout(),
        type: 'link',
        external: false,
      },
      {
        title: 'Book time',
        link: Routes.Main(),
        type: 'link',
        external: false,
      },
    ];
  }
  if (!user) {
    return [
      {
        title: 'Bli medlem',
        link: Routes.Register(),
        type: 'link',
        external: false,
      },
      {
        title: 'Logg inn',
        link: Routes.LogIn(),
        type: 'link',
        external: false,
      },
      {
        title: 'Book time',
        link: Routes.Main(),
        type: 'link',
        external: false,
      },
    ];
  }
};

const MobileModalMenu = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const user = useSelector(getCurrentUser);
  useEffect(() => {
    if (openMobileMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [openMobileMenu]);
  return (
    <div className={styles.mobileMenuContainer}>
      <HamburgerMenu active={openMobileMenu} onClick={() => setOpenMobileMenu(!openMobileMenu)} />
      {openMobileMenu && (
        <div className={styles.mobileMenuModalOverlay}>
          <div className={styles.mobileMenuContent}>
            {menuItems(user).map((menuItem) => (
              <li className={styles.listItem} key={menuItem.title}>
                {menuItem.external ? (
                  <a href={menuItem.link} className={styles.externalLink}>
                    {menuItem.title}
                  </a>
                ) : (
                  <Link to={menuItem.link} onClick={() => setOpenMobileMenu(false)}>
                    <H2 className={styles.text}>{menuItem.title}</H2>
                  </Link>
                )}
                <div className={styles.line}></div>
              </li>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const renderAppLinks = (user: User) => {
  if (!user) {
    return (
      <>
        <Link type="secondary" to={Routes.LogIn()} className={styles.routeLink}>
          Logg inn
        </Link>
        <CallToActionLink
          styleType="outlinedSecondary"
          to={Routes.Register()}
          className={styles.registerOrMemberButton}
        >
          Bli medlem!
        </CallToActionLink>
      </>
    );
  }

  return (
    <CallToActionLink
      styleType="outlinedSecondary"
      to={Routes.UserPage()}
      className={styles.registerOrMemberButton}
    >
      Min olio
    </CallToActionLink>
  );
};

const Navbar = () => {
  const user = useSelector(getCurrentUser);
  return (
    <>
      <div className={styles.container}>
        <Grid className={classNames(styles.wrapper)}>
          <div className={styles.leftContainter}>
            <div className={styles.logoWrapper}>
              <Logo />
            </div>
          </div>
          <div className={styles.rightContainer}>
            {renderAppLinks(user)}
            <CallToActionLink
              styleType="quaternary"
              to={Routes.Main()}
              className={styles.bookingButton}
            >
              Book time
            </CallToActionLink>
          </div>
          <div className={styles.mobileMenuButtonWrapper}>
            <MobileModalMenu />
          </div>
        </Grid>
      </div>
      <NavBarBanner bannerMessage={'summer'} showBanner={true} />
    </>
  );
};

export default Navbar;

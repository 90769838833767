import classNames from 'classnames';
import * as React from 'react';
import { BodyText } from '../texts';
import styles from './LoadingIndicator.module.css';

type Props = {
  loadingText?: string;
} & React.HTMLAttributes<HTMLElement>;

const LoadingIndicator: React.FunctionComponent<Props> = ({ className, loadingText, ...props }) => {
  return (
    <>
      {loadingText && (
        <BodyText style={{ marginBottom: 0, textAlign: 'center', color: '#8e4f59' }}>
          {loadingText}
        </BodyText>
      )}
      <div className={classNames(styles.loader, className)} {...props} />
    </>
  );
};

export default LoadingIndicator;

import classNames from 'classnames';
import * as React from 'react';
import { assertUnreachable } from '../../utils/ts.utils';
import styles from './FormInput.module.css';

type CallToActionStyleType = 'primary' | 'secondary' | 'uppercase';
export type FormType = 'name' | 'tel' | 'email' | 'text' | 'date' | 'password';

type Props = {
  styleType?: CallToActionStyleType;
  type?: FormType;
  placeholder?: string;
  value?: string;
  required?: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const styleTypeToClassName = (styleType: CallToActionStyleType) => {
  switch (styleType) {
    case 'primary':
      return styles.primary;
    case 'secondary':
      return styles.secondary;
    case 'uppercase':
      return styles.uppercase;
    default:
      assertUnreachable(styleType);
  }
};

const getType = (type?: FormType) => {
  switch (type) {
    case 'name':
      return 'name';
    case 'tel':
      return 'tel';
    case 'email':
      return 'email';
    case 'date':
      return 'date';
    case 'password':
      return 'password';
    default:
      return 'text';
  }
};
const getPlaceholder = (placeholder?: string, type?: FormType) => {
  if (placeholder) {
    return placeholder;
  }
  return getType(type);
};

const FormInputWith = ({
  className,
  required = true,
  styleType = 'primary',
  type = 'text',
  ...props
}: Props) => {
  return (
    <div className={classNames(styles.container, className)}>
      <input
        {...props}
        type={getType(type)}
        className={classNames(styles.input, styleTypeToClassName(styleType))}
        placeholder={getPlaceholder(props.placeholder, type)}
        required={required}
      />
    </div>
  );
};

export default FormInputWith;

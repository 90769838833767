import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../../../../../common/components/form/FormInput';
import SectionListTitle from '../../../../../common/components/section/SectionListTitle';
import { SmallText } from '../../../../../common/components/texts';
import { setPromocode } from '../../../forces/actions';
import { getEditMode, getPromocode, getUseOlioPoints } from '../../../forces/selectors';
import styles from '../SummarySection.module.css';

const Promocode = () => {
  const promocode = useSelector(getPromocode);
  const useOlioPoints = useSelector(getUseOlioPoints);
  const isEditMode = useSelector(getEditMode);
  const showPromoCode = !useOlioPoints && !isEditMode;
  const dispatch = useDispatch();

  if (!showPromoCode) return null;

  return (
    <>
      <SectionListTitle className={styles.title}>Kampanjekode</SectionListTitle>
      <FormInput
        className={styles.promocodeInput}
        styleType="uppercase"
        value={promocode || ''}
        name="promocode"
        placeholder="Har du en kampanjekode?"
        onChange={(event) => dispatch(setPromocode(event.target.value))}
      />
      <div className={styles.promocodeInfoText}>
        <SmallText>
          *Bookingoversikten vil ikke reflektere brukt kampanjekode, men rabatten vil bli trukket
          fra i salong. Ved bruk av kampanjekode betaler man i salong
        </SmallText>
      </div>
    </>
  );
};

export default Promocode;
